import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App, Lander, Dinner, Travel, Events, Private} from './App';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
      	<Route index element={<Lander />} />
      	<Route path="dinner" element={<Dinner />} />
   		<Route path="travel" element={<Travel />} />
        <Route path="private" element={<Private />} />
        <Route path="events" element={<Events />} />
      </Route>
    </Routes>
  </BrowserRouter>
);