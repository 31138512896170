import logo from './logo.svg';
import './App.css';
import React from 'react';
import {
  Link,
  Outlet
} from "react-router-dom";
export function App() {
  return (
    <div className="App">
      <header className="App-header">
      {/*
        <div className="App__Nav">
          <Link to="/">Home</Link>
          <Link to="travel">Adventures</Link>
          <Link to="dinner">Dinner</Link>
          <Link to="tara">Tara</Link>
          <Link to="jem">Jem</Link>
        </div>
        */
      }

      </header>
      <div className="App-body">
        <Outlet />
      </div>
    </div>
  );
}

const Nav = ()=> (
<div className="App__Nav">
    <Link to="dinner" className="c1">Dinner</Link>
    <Link to="travel" className="c2">Adventures</Link>
    <Link to="events" className="c3">Events</Link>
    <Link to="private" className="c4">Private</Link>
</div>
  )

const Home = () => (
<div className="home">
        <Link to="/">Home</Link>
      </div>
  )

export function Lander() {
  return(
    <div className="lander">
      <h1>Jem and Tara</h1>
      <Nav />
    </div>
    )
}

export function Travel() {
  return(
    <div className="travel">
      <h1>The Adventures of Jem and Tara</h1>
      <h2>National Park Adventures</h2>
      <h3>Acadia National Park</h3>
      <h3>Shenandoah National Park</h3>
      <h2>Other Adventures</h2>
      <h3>Italy</h3>
      <Home />
    </div>
    )
}

export function Dinner() {
  const d = Date.now()

  return (
    <div className="dinner">
      <h1>Dinner 12/9/2024</h1>
      <h2>Appetizer</h2>
      <p>Pastrami Style Red Pearl Onions</p>
      <h2>Main Course</h2>
      <p>Creamed White Pearl Onions</p>
      <p>Chicken Cutlets</p>
      <h2>Dessert</h2>
      <p>Sweet Potato Mash</p>
      <Home />
    </div>

    )
}

export function Private() {
  const privatePW = React.createRef(null)
  return (
    <div className="private">
      <div className="password">
        <input ref={privatePW} />
        <button>Submit</button>
      </div>
      <Home />
    </div>
    )
}

export function Events() {
  return (
    <div className="events">
      <Home />
    </div>
    )
}



